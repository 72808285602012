import React, { Component } from 'react';
import { Container, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Link, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PageHeading from '../../components/PageHeading/PageHeading';
import Layout from '../../components/Layout';
import ResourceTable from '../../components/ResourceTable/ResourceTable';

class SpecializedSkills extends Component {
  render() {
    const { data } = this.props;
    const { edges: resources } = data.allSpecializedSkillsListJson;
    const mappedResources = resources.map(resource => resource.node);

    return (
      <Layout>
        <Helmet
          title="VEYM | Specialized Skills"
          meta={[
            {
              name: 'description',
              content:
                'Specialized Skills/Chuyên Môn - Skills, Road Signs, Morse, Semaphore, Basic Knots, Phương Hướng',
            },
          ]}
        />
        <PageHeading>Specialized Skills</PageHeading>
        <Container>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/">Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Link to="/resources">Resources</Link>
            </BreadcrumbItem>
            <BreadcrumbItem active>Specialized Skills</BreadcrumbItem>
          </Breadcrumb>
        </Container>
        <Container>
          <br />
          <h3>Chuyên Môn</h3>
          <br />
          <p>
            Chuyên Môn là một trong các môn giáo dục của Phong Trào Thiếu Nhi.
            Phần chuyên môn bao gồm cả thể thao.
          </p>
          <p>
            Kỹ thuật chuyên môn (truyền tin, thủ công, dấu đường, cứu thương,
            nút dây…) có thể coi như những giải trí, hay việc nhàn rỗi, hợp sở
            thích. Kỹ thuật chuyên môn còn là bước khởi đầu giúp phát triển lòng
            thích thú làm việc. Rồi nếu được chuyên môn hóa nó sẽ mang tính cách
            hướng nghiệp và giúp kiện toàn hoạt động cá nhân nơi xã hội.
          </p>
          <p>Chuyên môn:</p>
          <div>
            <ul>
              <li>Giúp trẻ tìm được niềm vui khi làm việc.</li>
              <li>Giúp trẻ biết tháo vát sáng kiến, khéo léo.</li>
              <li>
                Giúp trẻ phát triển khả năng làm việc, sáng tạo và giàu tưởng
                tượng.
              </li>
              <li>
                Giúp trẻ luyện chí khí nhờ chuyên tâm làm việc và luyện tập.
              </li>
              <li>
                Giúp trẻ phát triển tinh thần xã hội nhờ sự cộng tác học tập và
                làm việc.
              </li>
            </ul>
          </div>
        </Container>
        <Container>
          <ResourceTable resources={mappedResources} />
        </Container>
      </Layout>
    );
  }
}

export default SpecializedSkills;

export const pageQuery = graphql`
  query SpecializedSkillsQuery {
    allSpecializedSkillsListJson {
      edges {
        node {
          name
          type
          links {
            fileType
            fileLink
          }
          categoryStyle {
            background
            border
          }
          categoryTextStyle {
            color
          }
          updated
        }
      }
    }
  }
`;

export const frontmatter = {
  title: 'Specialized Skills',
  path: '/resources/specializedskills',
  category: 'resources',
  description: 'Specialized skills',
  tags:
    'resource, specialized skills, chuyen mon, morse, semaphore, dau duong, nut giay, knots, phuong huong, ',
};
